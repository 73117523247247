html,
body,
#root {
    margin: 0;
    padding: 0;
    background: #eee;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}
